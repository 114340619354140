import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import  utils from '../../../utils';
import { CompreshensiveAssessment } from './compreshensiveAssessmentForm';

export function CompreshensiveAssessmentForm(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  const emailReceiver = cmsUtils.payload(item, 'EmailReceiver');
  const cssClass = utils.classNames('cms_item', 'htmlPanel', cmsOption.isCmsEdit && 'html', item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-html="true"
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>

      <div className="container contact-wrapper compreshensiveAssessmentForm" id="compreshensiveAssessmentForm">
        <CompreshensiveAssessment emailReceiver={emailReceiver} />
      </div>

    </div>
  )
}