/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./style";

import cmsUtils from "../../cms/utils/cmsUtils";
import utils from "../../utils";
import { ErrorMessage, Button, usePost } from "../../components";
import { getModel } from "./formModel";
import { useForm } from "../../form";
import { useState } from "react";
import env from "../../env";
import { useHistory } from "react-router-dom";

export function QuickQuote(props) {
  const item = props.item;
  //const type = cmsUtils.payload(item, 'Type');
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "quickQuote",
    item.cssClass || item.anchorName || ""
  );
  const model = getModel({});
  const form = useForm(model, { usePlaceholder: false });
  const render = (name) => form.renderControl(name, null);
  const emailReceiver = cmsUtils.payload(item, "EmailReceiver");
  const [sendingStatus, setSendingStatus] = useState(null);
  const post = usePost();
  const history = useHistory();

  // const [exData, setExData] = useState({
  //     ownerBuyer : '',
  //     businessPurposes : '',
  //     checkValidate : false
  // })

  const onSubmit = (e) => {
    //setExData({...exData, 'checkValidate' : true});
    //'ownerBuyer' : exData.ownerBuyer, 'businessPurposes' : exData.businessPurposes
    form.validateForm(
      e,
      () => {
        // const value = { ...form.getValue(), emailReceiver };
        // post.send(env.apiBase + "/api/contact/sendquickquote", value);
        // setSendingStatus('pending');

        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute("6LcWjyUqAAAAAE5cad1r_UfpxkQbwqbKU2q8CtnM", {
              action: "submit",
            })
            .then((token) => {
              const recaptchaValue = token;
              const value = {
                ...form.getValue(),
                emailReceiver: emailReceiver,
                recaptchaValue: recaptchaValue,
              };
              post.send(env.apiBase + "/api/contact/sendquickquote", value);
              setSendingStatus("pending");
            });
        });
      },
      (errors) => {}
    );
  };

  if (post.done() && sendingStatus === "pending") {
    //var value = form.getValue();
    //gtm.sendEvent('contactFormSubmitted', {contactEmail: value.email} )
    form.reset(model);
    setSendingStatus(null);
    setTimeout(() => {
      history.push("/thank-you");
    }, 1000);
  }

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      {cmsOption.isCmsEdit ? (
        <pre
          style={{
            fontSize: "12px",
            margin: "5px",
            padding: "10px",
            background: "#eee",
            border: "1px solid #ccc",
          }}
        >
          <b> {"Quick Quote"} </b>
        </pre>
      ) : (
        <form className='contactForm' style={{ maxWidth: "960px" }}>
          <div className='form-row'>
            <div className='col-sm-6 col-12 mb-2'>
              <label>First Name</label>
              {render("firstName")}
            </div>
            <div className='col-sm-6 col-12 mb-2'>
              <label>Last Name</label>
              {render("lastName")}
            </div>
            <div className='col-sm-6 col-12 mb-2'>
              <label>Email</label>
              {render("email")}
            </div>
            <div className='col-sm-6 col-12 mb-2'>
              <label>Loan Type</label>
              {render("loanType")}
            </div>
            {/* <div className="col-12 mb-2" style={{textAlign:'center'}} css={style.button__warpper}>
                            Are you a homeowner/buyer
                            <div css={style.button__container}>
                                <button type="button" className={`btn btn-primary ${exData.ownerBuyer === 'no' ? 'selected' : ''}`} style={{width:'49%',float:'left'}} onClick={(e)=>{
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setExData({...exData, 'ownerBuyer' : 'no'});
                                }}>
                                    NO
                                </button>
                                <button type="button" className={`btn btn-primary ${exData.ownerBuyer === 'yes' ? 'selected' : ''}`} style={{width:'49%',float:'right'}} onClick={(e)=>{
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setExData({...exData, 'ownerBuyer' : 'yes'});
                                }}>
                                    YES
                                </button>
                            </div>
                            <div css={style.error__text} className={`${(exData.ownerBuyer === '' && exData.checkValidate === true) ? 'display' : '' }`}>
                                The homeowner buyer field is required.
                            </div>
                        </div> */}
            <div className='col-sm-6 col-12 mb-2'>
              <label>Phone</label>
              {render("phone")}
            </div>
            <div className='col-sm-6 col-12 mb-2'>
              <label>Postcode</label>
              {render("postCode")}
            </div>
            {/* <div className="col-12 mb-2">
                            <label>Loan Amount</label>
                            {render('loanAmount')}
                        </div> */}
            {/* <div className="col-12 mb-2" style={{textAlign:'center'}} css={style.button__warpper}>
                            Finance For Business Purposes
                            <div css={style.button__container}>
                                <button type="button" className={`btn btn-primary ${exData.businessPurposes === 'no' ? 'selected' : ''}`} style={{width:'49%',float:'left'}} onClick={(e)=>{
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setExData({...exData, 'businessPurposes' : 'no'});
                                }}>
                                    NO
                                </button>
                                <button type="button" className={`btn btn-primary ${exData.businessPurposes === 'yes' ? 'selected' : ''}`} style={{width:'49%',float:'right'}} onClick={(e)=>{
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setExData({...exData, 'businessPurposes' : 'yes'});
                                }}>
                                    YES
                                </button>
                            </div>
                            <div css={style.error__text} className={`${(exData.businessPurposes === '' && exData.checkValidate === true) ? 'display' : '' }`}>
                                The business purposes field is required.
                            </div>
                        </div> */}
            {/* <div className="col-12 mb-2" css={style.bottom__text}>
                            By clicking submit, I acknowledge and accept the terms and conditions of the credit guide and quote.
                        </div> */}
          </div>
          <div css={style.submit__container}>
            <Button onClick={onSubmit} status={post.status}>
              {" "}
              SUBMIT{" "}
            </Button>
            <ErrorMessage errors={post.errors} />
            {post.status === "done" && "Successfully sent!"}
          </div>
        </form>
      )}
    </div>
  );
}
