import React from 'react';
import env from '../../../../env';
import { usePost } from '../../../../components';
import { useForm } from '../../../../form';
import { Button, ErrorMessage } from '../../../../components';
import { getRegisterModel } from './regFormModel';

export function Register({ ebookFileUrl, formCode }) {
  const model = getRegisterModel({});
  const form = useForm(model, { usePlaceholder: true });
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [downloadLink, setDownloadLink] = React.useState(null);
  const post = usePost();

  function onSubmit(e) {
    form.validateForm(e, () => {
      const value = { ...form.getValue(), ebookFileUrl: ebookFileUrl, formCode: formCode };
      post.send(env.apiBase + '/api/RegisterForDownload', value);
      setSendingStatus('pending');
    });

    console.log('submitting');
  }

  if (post.done() && sendingStatus === 'pending') {
    form.reset(model);
    setSendingStatus(null);
    const ret = {
      url: `${env.apiBase}/api/DownloadFile?q=${encodeURIComponent(post.response?.results?.linkCode)}`,
      filename: post.response?.results?.filename,
    };
    setDownloadLink(ret);

    // auto start
    downloadFile(ret.filename, ret.url);
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <div className='ebookDownloadRegistration'>
      {downloadLink && (
        <div>
          <p>Thank you for your registration.</p>
          <p>
            If the download doesn't start automatically in a few seconds, please{' '}
            <a href={downloadLink.url} target='_blank' rel='noopener noreferrer' download={downloadLink.filename}>
              click here
            </a>{' '}
            to access the download URL directly.
          </p>
        </div>
      )}
      {!downloadLink && (
        <form>
          <div className='form-row'>
            <div className='col-md-4'>{render('name')}</div>
            <div className='col-md-4'>{render('phone')}</div>
            <div className='col-md-4'>{render('email')}</div>
          </div>
          <div className='form-row'>
            <div className='col-md-12'>
              <div className='actions' style={{ marginTop: 0 }}>
                <Button onClick={onSubmit} status={post.status}>
                  Download
                </Button>
                <ErrorMessage errors={post.errors} />
                {post.status === 'done' && 'Successfully sent!'}
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

function downloadFile(filename, url) {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
