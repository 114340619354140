import { fb, validators } from '../../../../lib/form';

export function getRegisterModel(data) {
  const model = fb.group({
    name: [data.name || '', [validators.Required()], { label: 'Name', type: 'text' }],
    phone: [data.phone || '', [], { label: 'Phone', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
  });

  return model;
}
