//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from '../../cssInJs'


export default {
    pageTiles : css({
         display: 'flex',
         //justifyContent: 'flex-start',
         justifyContent: 'center',
         flexWrap: 'wrap',
         margin: '2rem -10px',
         marginLeft:'auto',
         marginRight:'auto'
    }),

    slider : css({
     '.slick-prev' : css(mq({
       left: ['0px !important', null, '-20px !important'],
     })),
     '.slick-next' : css(mq({
       right: ['0px !important', null, '-20px !important']
     })),
     '.slick-next:before, .slick-prev:before' : css({
       color:'#30588c'
     })
   }),

    pageTile__tile : css(mq({
         flex: '0 0 100%',
         maxWidth: '100%',
         padding: '10px'
    })),

    tile__content : css({
         display: 'flex',
         flexDirection: 'row',
         justifyContent: 'space-between',
         width: '100%',
         height: '100%',
         cursor: 'pointer',
         position: 'relative',
         flexWrap: 'wrap',
         '&:hover' : {
            textDecoration: 'none',
            '& .pageTile__bsyc__content__details' : {
                backgroundColor : 'rgb(48,88,140,0.9)',
                color: 'white',
            }
         }
    }),

    tile__bg : css(mq({
         height: '0',
         paddingTop: ['70%', '38%', '35%', '30%'],
         backgroundPosition: '50%',
         backgroundRepeat: 'no-repeat',
         backgroundSize: 'cover',
         flex: '1 1 300px',
    })),

    tile__details : css({
         overflow: 'hidden',
         flexGrow: '1',
         padding: '1rem 2rem',
         textAlign: 'center',
         width: '100%',
         bottom: '0',
         //backgroundColor: 'rgba(48,88,140,.2)',
         backgroundColor: 'rgba(53,153,165,.2)',
         //backgroundColor : '#77cf22',
         flex: '1 1 300px',
         transition: 'all .2s ease-in-out',
         color: '#666666',
         '& .pageTile__bsyc__container' : {
            position: 'relative',
            top: '44%',
            transform: 'translateY(-50%)',
            '& .pageTile__bsyc__title' : {
                fontFamily: 'acumin-pro, sans-serif',
                fontSize: '1.7rem',
                //textTransform: 'uppercase',
                fontWeight: '700',
                lineHeight: '1',
                margin: '0',
                paddingTop: '.5rem',
                paddingBottom: '.5rem',
                color:'inherit'
            },
            '& .pageTile__bsyc__desc' : {
                lineHeight: '1.3',
                fontSize: '.9rem',
                //color: '#3d3d3d',
                minHeight: '70px',
                color:'inherit',
                fontFamily: 'acumin-pro, sans-serif',
                fontWeight:'400'
             }
         }
    }),

    pageTile__tile2 : css(mq({
         flexBasis: ['100%', '46%', '30.33%' ],
         maxWidth : ['100%', '46%', '30.33%' ],
         //padding: '10px',
         position: 'relative',
         margin: "10px",
         overflow:'hidden',
         '&.useSlider' : css({
          flexBasis:'100%',
          maxWidth:'100%'
        })
    })),

    tile__content2 : css({
         '&:hover' : {
            textDecoration: 'none',
            '.pageTile__j5__content__details' : {
                 //opacity:'1 !important'
                 backgroundColor:'#30588c',
                 '.pageTile__j5__title, .pageTile__j5__desc' : {
                      color:'white'
                 }
            }
         } 
    }),

    tile__bg2 : css(mq({
          transition: 'all .2s ease-in-out',
          height: ['auto !important', '200px !important'],
          objectFit: 'cover',
    })),

    tile__details2 : css({
          transition: 'all .2s ease-in-out',
          marginTop: '0.5rem',
          //padding: '.5rem 2rem 1.5rem',
          //color: '#000',
          textDecoration: 'none',
          overflow: 'hidden',
          padding: '10px 15px',
          height:'100%',
          backgroundColor:'rgba(53, 153, 165, 0.2)'
     }),

    tile__details__wrapper2 : css({
          minHeight: '7rem'
    }),

    tile__details__title2 : css({
         //marginBottom : '1rem',
         color : '#666666',
         margin: '0px',
         fontSize : '1.4rem',
         fontWeight : '700',
         marginBottom: '0.5rem'
    }),
    tile__details__desc2 : css(mq({
          fontSize : ['0.9rem', '0.7rem'],
          fontWeight: '100',
          lineHeight: '1.2',
          color : '#666666'
    }))
}