/** @jsx jsx */
import { jsx } from '@emotion/core';
// import React from 'react';
import cmsUtils from '../../cmsUtils';
import utils from '../../../../utils';
import { Register } from './regForm';
import './ebookDownload.scss';

export function EBookDownload(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  //console.log(cmsOption)
  //console.log('item', item);
  const ebookFileUrl = cmsUtils.payload(item, 'EbookFileUrl');
  const formCode = cmsUtils.payload(item, 'FormCode');

  console.log(item, ebookFileUrl, formCode);
  const cssClass = utils.classNames('cms_item', 'ebookDownload', item.cssClass || item.anchorName || '');

  // const bgUrl = utils.site.resourcePath
  //   cmsOption.pageOptions && cmsOption.pageOptions.imagePath
  //     ? utils.site.resourcePath(cmsOption.pageOptions.imagePath)
  //     : utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));

  // const content = cmsUtils.payload(item, 'Content');

  // const s = getImageBannerStyle(heightSet);

  if (cmsOption.isCmsEdit)
    return (
      <div className={cssClass}>
        <div style={{ textAlign: 'center', padding: '10px', background: '#eee' }}>
          <h3>Ebook download</h3>
          <div>File: {ebookFileUrl}</div>
          <div>Form: {formCode}</div>
        </div>
      </div>
    );
  return (
    <div className={cssClass}>
      <Register ebookFileUrl={ebookFileUrl} formCode={formCode} />
    </div>
  );
}
