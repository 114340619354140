import React from "react";
import cmsUtils from "../../cms/utils/cmsUtils";
import utils from "../../utils";
import { SiteLink } from "../../components";
/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./style";
import Slider from "react-slick";
import button_default from "./button-on-default.png";
import button_hover from "./button-on-hover.png";

export function PageTilesBsyc(props) {
  const item = props.item;
  const tileType =
    "type" + (getPages(cmsUtils.payload(item, "TileType")) || "1");
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, "PageObjects"));
  const useSlider = cmsUtils.payload(item, "UseSlider") === "slider";

  const pageTileElts = pages.map((page, index) => {
    if (tileType === "type2") {
      return (
        <Tile2
          page={page}
          key={index}
          disableLink={cmsOption.isCmsEdit}
          idx={index}
          useSlider={useSlider}
        ></Tile2>
      );
    } else if (tileType === "type3") {
      return (
        <Tile3
          page={page}
          key={index}
          disableLink={cmsOption.isCmsEdit}
          idx={index}
        ></Tile3>
      );
    } else if (tileType === "type4") {
      return (
        <Tile4
          page={page}
          key={index}
          disableLink={cmsOption.isCmsEdit}
          idx={index}
          useSlider={useSlider}
        ></Tile4>
      );
    } else {
      return (
        <Tile
          page={page}
          key={index}
          disableLink={cmsOption.isCmsEdit}
          idx={index}
        ></Tile>
      );
    }
  });

  const cssClass = utils.classNames(
    "cms_item",
    "pageTiles__bsyc",
    item.cssClass || item.anchorName || ""
  );
  const cssClass2 = utils.classNames(
    "cms_item",
    "pageTiles tiles3",
    item.cssClass || item.anchorName || ""
  );
  const cssClass3 = utils.classNames(
    "cms_item",
    "pageTiles tiles4",
    item.cssClass || item.anchorName || ""
  );

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  if (useSlider && tileType === "type2") {
    return (
      <div
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        css={style.slider}
      >
        <Slider {...settings}>{pageTileElts}</Slider>
      </div>
    );
  } else if (useSlider && tileType === "type4") {
    return (
      <div
        className={cssClass3}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        css={style.slider}
      >
        <Slider {...settings}>{pageTileElts}</Slider>
      </div>
    );
  } else if (tileType === "type3" || tileType === "type4") {
    return (
      <div
        className={cssClass2}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        css={style.pageTiles}
      >
        {pageTileElts}
      </div>
    );
  } else {
    return (
      <div
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        css={style.pageTiles}
      >
        {pageTileElts}
      </div>
    );
  }
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const screenSize = window.innerWidth;
  const index = props.idx;
  return (
    <div css={style.pageTile__tile} className='pageTile__bsyc'>
      <SiteLink
        className='pageTile__bsyc__content'
        css={style.tile__content}
        to={disableLink ? "" : page.pageUrl}
      >
        {index % 2 === 0 || screenSize <= 550 ? (
          <div
            className='pageTile__bsyc__bg'
            css={style.tile__bg}
            style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          ></div>
        ) : null}
        <div
          css={style.tile__details}
          className='pageTile__bsyc__content__details'
        >
          <div className='pageTile__bsyc__container'>
            <div className='pageTile__bsyc__title'>{page.pageTitle}</div>
            <div className='pageTile__bsyc__desc'>
              {page.tileDesc || page.description}
            </div>
          </div>
        </div>
        {index % 2 !== 0 && screenSize >= 550 ? (
          <div
            className='pageTile__bsyc__bg'
            css={style.tile__bg}
            style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          ></div>
        ) : null}
      </SiteLink>
    </div>
  );
}

function Tile2(props) {
  const page = props.page;
  //console.log("aa", page);
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const useSlider = props.useSlider;
  return (
    <div
      css={style.pageTile__tile2}
      className={`pageTile__j5 ${useSlider && "useSlider"}`}
    >
      <SiteLink
        className='pageTile__j5__content'
        css={style.tile__content2}
        to={disableLink ? "" : page.pageUrl}
      >
        <img
          className='pageTile__j5__bg'
          css={style.tile__bg2}
          src={imageUrl}
          alt='image3'
        />
        <div
          css={style.tile__details2}
          className='pageTile__j5__content__details'
        >
          <div
            css={style.tile__details__wrapper2}
            className='pageTile__j5__details__wrapper'
          >
            <h3
              css={style.tile__details__title2}
              className='pageTile__j5__title'
            >
              {page.pageTitle}
            </h3>
            <div
              css={style.tile__details__desc2}
              className='pageTile__j5__desc'
            >
              {page.tileDesc || page.description}
            </div>
          </div>
        </div>
      </SiteLink>
    </div>
  );
}

function Tile3(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;

  return (
    <React.Fragment>
      <div className='pageTile tile tile3'>
        <div className='pageTile__content tile__content'>
          <div
            className='tile__bg'
            style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          ></div>
          <div className='pageTile__title_bg'>
            <div className='pageTile__title_text'>{page.pageTitle}</div>
          </div>
          <div className='pageTile_details'>
            <div className='pageTile__title'>{page.pageTitle}</div>
            <div className='pageTile_desc'>
              {page.tileDesc || page.description}
            </div>
            {/* eslint-disable-next-line */}
            <SiteLink
              className='tile_button'
              to={disableLink ? "" : page.pageUrl}
            >
              <img className='tile_icon' src={button_default} alt='read more' />
              <img
                className='tile_icon_hover'
                src={button_hover}
                alt='read more hover'
              />
            </SiteLink>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function Tile4(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const useSlider = props.useSlider;

  return (
    <React.Fragment>
      <div className={`pageTile tile tile4 ${useSlider && "useSlider"}`}>
        <SiteLink className='tile4__link' to={disableLink ? "" : page.pageUrl}>
          <div
            className='tile4__bg'
            style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          >
            <div className='pageTile4__readMore__container'>
              <div className='pageTile4__readMore thinBtn'>
                <img
                  className='tile_icon'
                  src={button_default}
                  alt='read more'
                />
                <img
                  className='tile_icon_hover'
                  src={button_hover}
                  alt='read more hover'
                />
              </div>
            </div>
          </div>
          <div className='pageTile4__content'>
            <div className='pageTile4__contentInner'>
              <div className='pageTile4__title'>{page.pageTitle}</div>
              <div className='pageTile4__desc'>
                {page.tileDesc || page.description}
              </div>
            </div>
          </div>
        </SiteLink>
      </div>
    </React.Fragment>
  );
}
