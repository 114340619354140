/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect } from 'react';
//import cmsUtils from '../../cms/utils/cmsUtils';
import utils from '../../utils';
//import style from './style';

export function StampDutyCalculator(props){
  
  const item = props.item;
  //const type = cmsUtils.payload(item, 'Type');
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'stamp__duty__calculator', item.cssClass || item.anchorName || '');

  useEffect(() => {
    
    if (cmsOption.isCmsEdit){
      return;
    }

    const existingScriptFirst = document.getElementById('stampDutyCalculatorFirst');
    if(existingScriptFirst){
        document.body.removeChild(existingScriptFirst);
    }
    const scriptFirst = document.createElement('script');
    scriptFirst.src = 'https://calcs.widgetworks.com.au/s/bigahabo/live.js';
    scriptFirst.id = 'stampDutyCalculatorFirst';
    document.body.appendChild(scriptFirst);
    
    const existingScript = document.getElementById('stampDutyCalculator');
    if(existingScript){
        document.body.removeChild(existingScript);
    }
    const script = document.createElement('script');
    script.src = 'https://calcs.widgetworks.com.au/widget/widget-scout.min.js';
    script.id = 'stampDutyCalculator';
    document.body.appendChild(script);

    // eslint-disable-next-line
  }, []);

  if (cmsOption.isCmsEdit){
    return (
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b> Stamp Duty Calculator </b>
        </pre>
      </div>
    )
  }

  return(
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} style={{maxWidth:'960px',margin:'2rem auto'}}>
      <iframe title = "Stamp Duty Calculator" id="wiwo-bigahabo" src="about:blank" frameBorder="0" width="100%" height="200" data-wiwo-init="false"></iframe>
    </div>
  )

}