import React, { useLayoutEffect, useRef } from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
import BottomDrawer from '../../components/bottomDrawer';
import {ReactComponent as ReactLogo} from './nav-icon-white.svg';

export function BottomDock(props) {
  const links = [
    { url: '/about', title: 'ABOUT' },
    { url: '/applications-calculators', title: 'CALCULATORS' },
    { url: '/', title: 'CALL' },
    { url: '/services', title: 'SERVICES' },
    // { url: '/team', title: 'TEAM' },
    { url: '/blog', title: 'BLOG' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>

      {
        link.title === 'CALL' ?
          <a href="tel:0883638800" className={`bottomMenu__link`}>
            <ReactLogo className='call__icon' />
            <div>{link.title}</div>
          </a> :
          <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
            <div className="icon"></div>
            <div>{link.title}</div>
          </SiteLink>
      }

    </li>
  );

  const bottomMenu = useRef(null);
  useLayoutEffect(() => {
    //console.log('useLayoutEffect');
    const slider = new BottomDrawer(bottomMenu.current);
    setTimeout(() => slider.init(), 1000)
  }, [])

  return (
    <>
      <nav className="bottomMenu" ref={bottomMenu}>
        <div className="bottomMenu__bar"></div>
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
      <div className="bottomMenuPadding"></div>
    </>
  );
}
