import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, 0, 80],
    height: [44,null,56],
    //height: [21, 20, 20],
    background: 'rgba(255,255,255,0.9)',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  // topScrolled: css(mq({
  //   height: [44, 0, 56],
  // })),
  logo: css(mq({
    marginBottom: ['4px','0px']
  })),
  logoImg: css(mq({
    height: [22, 36, 36],
    //marginTop: ['31px', '42px','42px', '55px'],
  })),
  logoScrolled: css(mq({

  })),
  curve : css(mq({
    top:['20px'],
    zIndex:'-1'
  })),

  header__call__button : mq({
    position: 'fixed',
    right: ['45px',null,'60px'],
    maxWidth: ['27px',null,'35px'],
    'img' : {
      width:'100%'
    }
  })
  
}