import React from "react";

import utils from "../../utils";
import { items } from "../../site/_cmsItems/items-generated";
import { BannerPanel } from "./bannerPanel/view";
import { BannerItem } from "./bannerItem/view";
import { Panel } from "./panel/view";
import { Html } from "./html/view";
// import { TestimonialPanel } from './testimonialPanel/view';
// import { TestimonialItem } from './testimonialItem/view';
import { ImagePanel } from "./imagePanel/view";
//import { PageTiles } from './pageTiles/view';
import { PageTilesBsyc } from "../../components/pageTile--bsyc/view";
import { SingleBanner } from "./singleBanner/view";
import { AccordionPanel } from "./accordionPanel/view";
import { ContactForm } from "./contactForm/view";
import { CompreshensiveAssessmentForm } from "./compreshensiveAssessmentForm/view";
import { YoutubeBannerPanel } from "./youtubeBannerPanel/view";
import { Code } from "./code/view";
import { BlogPosts } from "./blogPosts/view";
import { BlogPostTitle } from "./blogPostTitle/view";
import { VideoHolder } from "./videoHolder/view";
import { VideoItem } from "./videoItem/view";
import { MultiColumnPanel } from "./multiColumnPanel/view";
import { ExpandableHtml } from "./expandableHtml/view";
import { TestimonialPanel } from "../../components/testimonial/index";
import { PhotoGallery } from "../../components/photoGallery/view";
import { Calculator } from "../../components/calculator/view";
import { QuickQuote } from "../../components/quickQuote/view";
import { SmallBusinessLoan } from "../../components/smallBusinessLoan/view";
import { StampDutyCalculator } from "../../components/stampDutyCalculator/view";
import { RotatingTextBanner } from "./rotatingTextBanner/view";
import { TextBannerItem } from "./textBannerItem/view";

export function Item(props) {
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems, cmsOption);

  let itemElt = <></>;

  const found = utils.array.find(items, (x) => x.match(item, cmsOption));
  //console.log(found)
  if (found) {
    return found.itemElt(item, cmsOption);
  }

  if (item.itemTypeCode === "Panel") {
    itemElt = <Panel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "Html") {
    itemElt = <Html item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "BannerPanel") {
    itemElt = <BannerPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "BannerItem") {
    itemElt = <BannerItem item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "TestimonialPanel") {
    const setting = {
      startRatingColor: "#3599a5",
    };
    itemElt = (
      <TestimonialPanel item={item} cmsOption={cmsOption} {...setting} />
    );
  } else if (item.itemTypeCode === "ImagePanel") {
    itemElt = <ImagePanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "PageTiles") {
    itemElt = <PageTilesBsyc item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "SingleBanner") {
    itemElt = <SingleBanner item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "AccordionPanel") {
    itemElt = <AccordionPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "ContactForm") {
    itemElt = <ContactForm item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "CompreshensiveForm") {
    itemElt = (
      <CompreshensiveAssessmentForm item={item} cmsOption={cmsOption} />
    );
  } else if (item.itemTypeCode === "YoutubeBannerPanel") {
    itemElt = <YoutubeBannerPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "Code") {
    itemElt = <Code item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "Blog-Posts") {
    itemElt = <BlogPosts item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "Blog-PostTitle") {
    itemElt = <BlogPostTitle item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "MultiColumnPanel") {
    itemElt = <MultiColumnPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "ExpandableHtml") {
    itemElt = <ExpandableHtml item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "VideoHolder") {
    itemElt = <VideoHolder item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "VideoItem") {
    itemElt = <VideoItem item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "PhotoGallery") {
    itemElt = <PhotoGallery item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "Calculator") {
    itemElt = <Calculator item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "QuickQuoteForm") {
    itemElt = <QuickQuote item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "SmallBusinessLoan") {
    itemElt = <SmallBusinessLoan item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "StampDutyCalculator") {
    itemElt = <StampDutyCalculator item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "RotatingTextBanner") {
    itemElt = <RotatingTextBanner item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "TextBannerItem") {
    itemElt = <TextBannerItem item={item} cmsOption={cmsOption} />;
  } else {
    itemElt = <div>{item.itemTypeCode}</div>;
  }

  // const subItemsElts = subItems.map((subItem, index) =>
  //   <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  // );
  return (
    <>
      {itemElt}
      {/* {subItemsElts} */}
    </>
  );
}
