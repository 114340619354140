import React, { useState } from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { Helmet } from "react-helmet-async";

export function AccordionPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const bgColor = cmsUtils.payload(item, "BgColor");
  const imageUrl = cmsUtils.payload(item, "ImageUrl");
  const backgroundTile = cmsUtils.payload(item, "BackgroundTile");

  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  let bgUrl = null;
  if (imageUrl) bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(
      `/dist/transparent-textures/textures/${backgroundTile}`
    );
  const cssClass = utils.classNames(
    "cms_item",
    "accordionPanel",
    "contentPanel--",
    item.cssClass || item.anchorName || ""
  );

  var mainEntity = [];
  subItems.forEach((subItem) =>
    mainEntity.push({
      "@type": "Question",
      "name": subItem.payloads[0].value,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": subItem.payloads[1].value,
      },
    })
  );
  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": mainEntity,
  };

  const subItemsElts = subItems.map((subItem, index) => (
    <div className='wrapper' key={index}>
      <div className={selected === index ? "accordion show" : "accordion"}>
        <div className='item'>
          <div
            className={selected === index ? "title show" : "title"}
            onClick={() => toggle(index)}
          >
            <h4>{subItem.payloads[0].value}</h4>
            <span className='open_button'></span>
          </div>
          <div className={selected === index ? "content show" : "content"}>
            <div
              className='content_text'
              dangerouslySetInnerHTML={{ __html: subItem.payloads[1].value }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <Helmet>
        <script type='application/ld+json'>{JSON.stringify(schema)}</script>
      </Helmet>
      <section
        className={cssClass}
        style={{
          backgroundColor: `${bgColor}`,
          backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl),
        }}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div className='container'>
          <div className='accordion_container'>{subItemsElts}</div>
        </div>
      </section>
    </>
  );
}
