/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

import SimpleReactLightbox, {SRLWrapper}  from 'simple-react-lightbox'
import utils from '../../utils';
import cmsUtils from '../../cms/utils/cmsUtils';

const options = {
    settings: {
        disablePanzoom: true,
    },
    caption:{
        showCaption: false
    },
    thumbnails:{
        showThumbnails: false,
    }
};

export function PhotoGallery(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'photoGallery', item.cssClass || item.anchorName || '');
    const _images = cmsUtils.payload(item, 'ImageUrl') ? cmsUtils.payload(item, 'ImageUrl').split("|") : [];
    
    const imageElts = _images.map((im, index) =>
        <div css = {style.photoContainer} key = {index}>
            <img css = {style.photo} src= {im} alt="Caption" />
        </div>
    );
    
    return(
        <div css={style.photoGallery} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <SimpleReactLightbox>
                <div css = {style.photos} className="photos"> 
                    <SRLWrapper options = {options}>
                        {/* <div css = {style.photoContainer}>
                            <img css = {style.photo} src="https://dummyimage.com/2560x800/333333/fff" alt="Caption" />
                        </div>
                        <div css = {style.photoContainer}>
                            <img css = {style.photo} src="https://dummyimage.com/2560x800/333333/fff" alt="Caption" />
                        </div> */}
                        { imageElts }
                        <div css = {style.photoEmpty}></div>
                        <div css = {style.photoEmpty}></div>
                        <div css = {style.photoEmpty}></div>
                        <div css = {style.photoEmpty}></div>
                        <div css = {style.photoEmpty}></div>
                        <div css = {style.photoEmpty}></div>
                        <div css = {style.photoEmpty}></div>
                    </SRLWrapper>
                </div>
            </SimpleReactLightbox>
        </div> 
    );   
}