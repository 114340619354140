import { css } from '@emotion/core';
import { mq } from '../../cssInJs';
export default {
  button: css(mq({
    position: 'absolute',
    left: '1rem',
    lineHeight: '1.6rem',
    color: '#30588c',
    fontSize: ['0.8rem', null, '0.8rem'],
    //top: '-3px'
    top:'50%',
    transform: 'translateY(-50%)',
    'svg' : {
      marginBottom: '2px'
    }
  })),
}