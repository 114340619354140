import { fb, validators } from '../../../lib/form';

export function getCompreshensiveAssessmentModel(data) {

  const brokerList = [
    { name: 'Who is your Go Loans broker?*', value: '' },
    { name: 'Simon Norris', value: 'Simon Norris' },
    { name: 'David Garner', value: 'David Garner' },
    { name: 'Con Tsimikils', value: 'Con Tsimikils' },
    { name: 'Sarah Lai', value: 'Sarah Lai' },
    { name: 'R-Jay Teav', value: 'R-Jay Teav' },
    // { name: 'Mitch Hadjinicolaou', value: 'Mitch Hadjinicolaou' },
    { name: 'Daniel Ball', value: 'Daniel Ball' },
    // { name: 'Tanya Willson', value: 'Tanya Willson' },
    // { name: 'Lainie Darams', value: 'Lainie Darams' },
    // { name: 'Sara Colangelo', value: 'Sara Colangelo' },
    // { name: 'ANY', value: 'ANY' }
  ];

  const model = fb.group({
    // name: [data.name || '', [validators.Required()], { label: 'Name', type: 'text' }],
    // phone: [data.phone || '', [validators.Required()], { label: 'Phone', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
    purchase: [ data.purchase || false, [], { label: `Purchase`, type: 'checkbox' }],
    refinance: [ data.purchase || false, [], { label: `Refinance`, type: 'checkbox' }],
    debtConsolidation: [ data.purchase || false, [], { label: `Debt Consolidation`, type: 'checkbox' }],
    broker: [data.broker || '', [validators.Required()], { label: null, type: 'select', options: brokerList }],
    // enquiry: [data.enquiry || '', [validators.Required()], { label: 'Your Message', type: 'textarea' }]
  });

  return model;
}