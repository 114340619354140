import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

export function BannerItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));
  const bgUrl2 = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl2'));

  const captionTitle = cmsUtils.payload(item, 'CaptionTitle2') || cmsUtils.payload(item, 'CaptionTitle');
  const linkUrl = cmsUtils.payload(item, 'LinkUrl2') || cmsUtils.payload(item, 'LinkUrl');
  
  const titlePanel = captionTitle ? (
    linkUrl ? (<div>
      <div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>
      <SiteLink to={linkUrl} className="banner-item__link">READ MORE</SiteLink>
    </div>
    ) : (<div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>)
  ) : null;

  const linkOverlay = linkUrl && !captionTitle ? (
    <SiteLink className="bannerItem__overlay" to={linkUrl}></SiteLink>
  ) : null;

  const content = cmsUtils.payload(item, 'Content');
  const cssClass = utils.classNames('cms_item', 'banner-item', 'bannerSize', (content && content !== "") ? 'content' : '', item.cssClass || item.anchorName || '', titlePanel && 'bannerHasTitlePanel');
  const htmlBlock = React.useRef(null);
  const cssClass2 = utils.classNames('cms_item', 'htmlPanel', cmsOption.isCmsEdit && 'html', item.cssClass || item.anchorName || '');

  // return (
  //   <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
  //     style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}>
  //     {titlePanel && <div className="banner_item__text">{titlePanel}</div>}
  //     {linkOverlay && linkOverlay}
  //     <div ref={htmlBlock} className={cssClass2} data-cms-html="true" data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
  //       dangerouslySetInnerHTML={{ __html: content }}>
  //     </div>
  //   </div>
  // );

  



  return (
    <div className={cssClass} style={{ backgroundImage: window.innerWidth > 576 ? utils.css.bgUrlStyle(bgUrl) : ((bgUrl2 && bgUrl2 !== '') ? utils.css.bgUrlStyle(bgUrl2) : utils.css.bgUrlStyle(bgUrl) )}}>
      {titlePanel && <div className="banner_item__text">{titlePanel}</div>}
      
      {
        (!cmsOption.isCmsEdit && linkOverlay) && linkOverlay
      }
      
      <div ref={htmlBlock} className={cssClass2} data-cms-html="true" data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
        dangerouslySetInnerHTML={{ __html: content }}>
      </div>
    </div>
  );
}