import { fb, validators } from '../../lib/form';
export function getModel(data) {
    const loanTypeList = [
        {name : '---', value : ''},
        {name : 'Purchase', value : 'Purchase'},
        {name : 'Refinance', value : 'Refinance'},
        {name : 'Car Loan', value : 'Car Loan'},
        {name : 'Personal Loan', value : 'Personal Loan'},
        {name : 'Commercial Loan', value : 'Commercial Loan'},
        {name : 'Business Loan', value : 'Business Loan'},
        {name : 'Other', value : 'Other'}
    ];

    const model = fb.group({
        firstName: [data.firstName || '', [validators.Required()], { label:null, type: 'text' }],
        lastName: [data.lastName || '', [validators.Required()], { label:null, type: 'text' }],
        email: [data.email || '', [validators.Required(), validators.Email()], { label:null, type: 'email' }],
        loanType: [data.loanType || '', [validators.Required()], { label:null, type: 'select', options: loanTypeList }],
        phone: [data.phone || '', [validators.Required()], { label:null, type: 'text' }],
        postCode: [data.postCode || '', [validators.Required()], { label:null, type: 'text' }]
        //loanAmount: [data.loanAmount || '', [validators.Required()], { label:null, type: 'number' }]
  });
  return model;
}