import utils from "../../../utils";
import cmsUtils from "../../../cms/utils/cmsUtils";
import { IoIosStar } from "react-icons/io";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./style";

export function TestimonialItem(props) {
  const item = props.item;
  //const cmsOption = props.cmsOption || {};
  // console.log("TestimonialItem", item);
  const cssClass = utils.classNames(
    "cms_item",
    "testimonialItem",
    item.cssClass || item.anchorName || ""
  );
  const startRatingColor =
    props.startRatingColor && props.startRatingColor !== ""
      ? props.startRatingColor
      : "black";

  const title = cmsUtils.payload(item, "Title");
  const desc = cmsUtils.payload(item, "Desc");
  const bottom = cmsUtils.payload(item, "BottomText");
  const imageUrl = cmsUtils.payload(item, "ImageUrl") || "";

  const ratingValue = parseInt(cmsUtils.payload(item, "RatingStar"));
  const letterLength = (title.length >= 16 ? 26 : 0) + desc.length;
  //const letterLength = title.length  + desc.length;
  const convertedDesc =
    letterLength >= 250 ? desc.slice(0, 214).trim() + " . . ." : desc;
  //console.log('dddd', desc.length, title);
  var rating = [];

  for (var i = 0; i < ratingValue; i++) {
    rating.push(<IoIosStar key={i} style={{ color: startRatingColor }} />);
  }

  return (
    // data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
    <div css={style.testimonialItem} className={cssClass}>
      {imageUrl && imageUrl !== "" && (
        <div css={style.testimonialItem_img}>
          <img width='100%' src={imageUrl} alt='logo' />
        </div>
      )}

      <h4 css={style.testimonialItem_title} className='title'>
        {title}
      </h4>
      <div className='rating'>{rating}</div>
      {/* style={{ flexBasis: props.desH + "px" }} */}
      <div css={style.testimonialItem_desc} className='desc'>
        {convertedDesc}
      </div>
      <h5 css={style.testimonialItem_bottom} className='bottom'>
        {bottom}
      </h5>
    </div>
  );
}
