//import cmsUtils from '../../cms/utils/cmsUtils';
import utils from '../../utils';
//import { SiteLink } from '../../components';
import { useEffect } from 'react';

/** @jsx jsx */
import { jsx } from '@emotion/core'
//import style from './style';

export function SmallBusinessLoan(props){

    const item = props.item;
    //const type = cmsUtils.payload(item, 'Type');
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'smallBusinessLoan__item', item.cssClass || item.anchorName || '');

    useEffect(() => {
        const existingScript = document.getElementById('smallBusinessLoan');
        if(existingScript){
            document.body.removeChild(existingScript);
        }
        const script = document.createElement('script');
        script.text = `(function() {var t = document.createElement("script");t.type = "text/javascript";
        t.id = "prospaFrameLoader";t.async = !0;t.src = "https://iframe.prospa.com/frameLoader.min.js" +"?_d=" + (new Date).getTime();
        var n = document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t, n);})();`;
        script.id = 'smallBusinessLoan';
        document.body.appendChild(script);
    }, []);

    return(
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} style={{maxWidth:'960px',margin:'auto'}}>
            {
                cmsOption.isCmsEdit ?
                <pre style={{fontSize: '12px', margin:'5px', padding: '10px', background:'#eee', border: '1px solid #ccc'}}>
                    <b> { 'Small Business Loan Widget'} </b>
                </pre> :
                <div style={{marginLeft:'-13px',marginRight:'-13px'}}>
                    <prospa-form theme="blue" broker-id="30924721"></prospa-form>
                </div>
            } 
        </div>
    )
}