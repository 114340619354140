import React, { useEffect } from 'react';
import { Item } from '../../items/item';
import cmsUtils from '../../utils/cmsUtils';
import  utils from '../../../utils';
import Slider from "react-slick";

export function BannerPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems);

  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';

  const slides = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  // var settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 1500,
  //   autoplaySpeed: 3000,
  //   fade: true,
  //   autoplay: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1
  // };

  var settings = cmsOption.isCmsEdit ? {
    dots: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows : false,
    accessibility : false
  } : {
    dots: false,
    infinite: true,
    speed: 1500,
    //speed: 2000,
    //autoplaySpeed: 3000,
    autoplaySpeed: 6000,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const cssClass = utils.classNames('cms_item', 'bannerPanel', `bannerWrapperSize--${heightSet}`, item.cssClass || item.anchorName || '');
  const _slider = React.useRef(null);
  const _nextpreButton = React.useRef(null);
  
  useEffect(() => {
    if(!cmsOption.isCmsEdit){
      return;
    }
    _nextpreButton.current.querySelector("#pre").addEventListener('click', event =>{
      _slider.current.slickPrev();
    });

    _nextpreButton.current.querySelector("#next").addEventListener('click', event =>{
      _slider.current.slickNext();
    });

  }, [cmsOption.isCmsEdit]);

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <Slider {...settings} ref={_slider}>
        {slides}
      </Slider>
      <div className="bottom-curve curve" style={{display:'block'}}>
          <svg viewBox="0 0 500 50" preserveAspectRatio="none">
            <path d="M0,80 L0,10 Q250,80 500,10 L500,80 Z" fill="#fff" />
          </svg>
      </div>
      {
        cmsOption.isCmsEdit ?
        <div style={{position:'absolute',top:'0px',display:'block',zIndex:'999'}} ref={_nextpreButton}>
          <button id="pre"> pre </button>
          <button id="next"> next </button>
        </div> : null
      }
    </div>
  );
}