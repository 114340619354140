//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
    error__text : css(mq({
        marginTop: '3rem',
        color: 'red',
        fontSize: '0.8rem',
        display: 'none',
        '&.display' : {
            display: 'block'
        }
    })),
    button__container : css({
        marginBottom:'0.5rem',
        marginTop:'0.5rem',
        '& .btn.selected' : {
            background: '#2c7e88'
        }
    }),
    button__warpper : css({
        marginBottom:'1rem !important'
    }),
    bottom__text : css(mq({
        fontSize: '0.9rem',
        textAlign:'center',
        background:'rgba(0,0,0,0.1)',
        padding: '0.8rem 0.5rem'
    })),
    submit__container : css({
        margin: '0.4rem 0px',
        textAlign: 'center',
        '& button' : {
            minWidth:'200px',
            marginRight: '1rem'
        }
    })
}