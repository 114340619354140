import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";

export function TextBannerItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));

  const captionTitle =
    cmsUtils.payload(item, "CaptionTitle2") ||
    cmsUtils.payload(item, "CaptionTitle");
  const captionTitleAnimation =
    cmsUtils.payload(item, "CaptionAnimation") === "tracking-in";
  const captionDescription = cmsUtils.payload(item, "CaptionDescription");
  const captionDescriptionAnimation =
    cmsUtils.payload(item, "DescriptionAnimation") === "fade-up";
  const captionButton =
    cmsUtils.payload(item, "CaptionButton") &&
    cmsUtils.payload(item, "CaptionButton") !== ""
      ? cmsUtils.payload(item, "CaptionButton")
      : "READ MORE";
  const linkUrl =
    cmsUtils.payload(item, "LinkUrl2") || cmsUtils.payload(item, "LinkUrl");
  const alignV = cmsUtils.payload(item, "VAlign") || "50%";

  const titlePanel = captionTitle ? (
    linkUrl ? (
      captionDescription ? (
        <div>
          <div
            className={`banner-item__title ${
              captionTitleAnimation ? "banner-item__title__animation" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: captionTitle }}
          ></div>
          <div
            className={`banner-item__des ${
              captionDescriptionAnimation ? "banner-item__des__animation" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: captionDescription }}
          ></div>
          <SiteLink to={linkUrl} className='banner-item__link2'>
            {" "}
            {captionButton}{" "}
          </SiteLink>
        </div>
      ) : (
        <div>
          <div
            className={`banner-item__title ${
              captionTitleAnimation ? "banner-item__title__animation" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: captionTitle }}
          ></div>
          <SiteLink to={linkUrl} className='banner-item__link2'>
            {" "}
            {captionButton}{" "}
          </SiteLink>
        </div>
      )
    ) : captionDescription ? (
      <div>
        <div
          className={`banner-item__title ${
            captionTitleAnimation ? "banner-item__title__animation" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: captionTitle }}
        ></div>
        <div
          className={`banner-item__des ${
            captionDescriptionAnimation ? "banner-item__des__animation" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: captionDescription }}
        ></div>
      </div>
    ) : (
      <div>
        <div
          className={`banner-item__title ${
            captionTitleAnimation ? "banner-item__title__animation" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: captionTitle }}
        ></div>
      </div>
    )
  ) : null;

  const linkOverlay =
    linkUrl && !captionTitle ? (
      <SiteLink className='bannerItem__overlay' to={linkUrl}></SiteLink>
    ) : null;

  const cssClass = utils.classNames(
    "cms_item",
    "banner-item",
    "text-banner-item",
    "bannerSize",
    item.cssClass || item.anchorName || "",
    titlePanel && "bannerHasTitlePanel"
  );

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      style={{
        backgroundImage: utils.css.bgUrlStyle(bgUrl),
        backgroundPositionY: alignV,
      }}
    >
      {titlePanel && <div className='banner_item__text2'>{titlePanel}</div>}
      {linkOverlay && linkOverlay}
    </div>
  );
}
