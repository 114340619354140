import React, { useState, useEffect } from "react";
import { useRouter } from "../../../components";
import { useGet } from "../../../components";
import { Item } from "../../items/item";
import { NotFound } from "../../../components";
import env from "../../../env";
import { useStore } from "../../../store";
import { ErrorPopup, Loading } from "../../../components";
import { useInstagram } from "../../../components";
import { MetaTag } from "../../../components";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { gtm } from "../../../lib/tracking";
import { Helmet } from "react-helmet-async";

const log = (...args) => {
  //console.log(args);
};
const areEqual = (prevProps, nextProps) => {
  return prevProps.pageData === nextProps.pageData;
};
const PageMemo = React.memo(Page, areEqual);

export function ViewPage() {
  const { dispatch } = useStore();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [data, setData] = useState(null);
  //const path = usePath();
  //log('view page', path);
  const { pathname } = useRouter();
  useInstagram();

  const get = useGet();
  log("0. viewpage ready");

  useEffect(() => {
    log("*. start loading");
    setPageLoaded(false);
    get.send(env.apiBase + "/api/cms/GetPublishedPage?path=" + pathname);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "SET_PAGE", payload: data.data });
      dispatch({ type: "SET_MENU", payload: data.menu });
    }
    // eslint-disable-next-line
  }, [data]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  //log(router.pathname);
  //log(get.loading());
  log("1. viewpage component", pageLoaded, get.response);

  const pageData = get.response.data;
  //const menuData = get.response.menu;
  const cmsOption = {};
  log(pageLoaded, !pageLoaded && !!pageData);
  if (!pageLoaded && pageData) {
    //log(pageLoaded, pageData, menuData)
    log("2. dispatching page & menu");
    setPageLoaded(true);
    setData(get.response);
    document.title = pageData.pageTitle;
    gtm.pageView(pathname, pageData.pageTitle);
    return null;
  } else if (!pageData) {
    return <NotFound />;
  }

  log("3. call rendering memo");
  return <PageMemo pageData={pageData} cmsOption={cmsOption} />;
}

function Page(props) {
  const pageData = props.pageData;
  const cmsOption = props.cmsOption;
  const datePublishedForBlog = props.datePublishedForBlog;
  const heroImageUrl =
    "https://www.goloans.com.au" + cmsUtils.payload(pageData, "ImageUrl");
  const description = cmsUtils.payload(pageData, "Description");
  cmsOption.pageData = pageData;
  // document.title = pageData.pageTitle;
  log("4. page rendering", pageData, cmsOption);
  const sectionItems = pageData.sections.map((section, index) => {
    return (
      <Section
        item={section}
        key={section.sectionName + index}
        cmsOption={cmsOption}
      ></Section>
    );
  });

  const homeStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Go Loans Mortgage Brokers",
    "alternateName": "Go Loans",
    "brand": {
      "@type": "Brand",
      "name": "Go Loans",
    },
    "url": "https://www.goloans.com.au",
    "logo": "https://www.goloans.com.au/assets/logos/logo.png",
    "sameAs": [
      "https://www.instagram.com/goloans_aus",
      "https://www.linkedin.com/company/go-loans",
      "https://www.facebook.com/goloans",
    ],
    "aggregateRating": {
      "@type": "AggregateRating",
      "url":
        "https://www.google.com/search?q=go+loans&sca_esv=03a2317566d16611&sca_upv=1&rlz=1C1ONGR_enAU1082AU1082&sxsrf=ADLYWIKTjlWxAmdCuyvaITmzGnbKbSkHIQ%3A1719537517553&ei=bQ9-Zqa7Ia2RseMPkKiSuAc&ved=0ahUKEwjmpaW3kP2GAxWtSGwGHRCUBHcQ4dUDCBA&uact=5&oq=go+loans&gs_lp=Egxnd3Mtd2l6LXNlcnAiCGdvIGxvYW5zMhMQLhiABBgUGMcBGIcCGI4FGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMiIQLhiABBgUGMcBGIcCGI4FGK8BGJcFGNwEGN4EGOAE2AEBSP4JUABYrAhwAHgBkAEAmAHSAaABrwuqAQUwLjYuMrgBA8gBAPgBAZgCCKAC4QvCAgoQIxiABBgnGIoFwgIREC4YgAQYkQIY0QMYxwEYigXCAgsQABiABBiRAhiKBcICChAAGIAEGEMYigXCAhAQABiABBixAxhDGIMBGIoFwgINEAAYgAQYsQMYQxiKBcICFhAuGIAEGLEDGNEDGEMYgwEYxwEYigXCAhQQLhiABBiRAhixAxjRAxjHARiKBcICExAuGIAEGEMYxwEYigUYjgUYrwHCAhAQLhiABBjRAxhDGMcBGIoFwgIQEAAYgAQYsQMYQxjJAxiKBcICCxAAGIAEGJIDGIoFwgIOEC4YgAQYxwEYjgUYrwHCAhQQLhiABBiRAhixAxjHARiKBRivAcICBRAuGIAEwgIiEC4YgAQYQxjHARiKBRiOBRivARiXBRjcBBjeBBjgBNgBAZgDALoGBggBEAEYFJIHBTAuNi4yoAfzVQ&sclient=gws-wiz-serp#lrd=0x6ab0ced8608d47a5:0x6bcb088970106dc6,1,,,,",
      "ratingValue": "4.9",
      "reviewCount": "285",
    },
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Kent Town",
      "addressRegion": "SA",
      "postalCode": "5067",
      "streetAddress": "19 Rundle Street",
    },
    "faxNumber": "(08)8362-2025",
    "keywords":
      "Go Loans, Go Loans Australia, Mortgage Brokers, Adelaide, Home Loans, Refinancing",
    "telephone": "08-8363-8800",
    "openingHours": ["Mo-Fr 9:00-17:00"],
  };
  const servicesStructuredData = {
    "@context": "https://schema.org/",
    "@type": "Service",
    "serviceType": "Home Loans and Refinancing",
    "provider": {
      "@type": "LocalBusiness",
      "name": "Go Loans",
    },
    "areaServed": {
      "@type": "State",
      "name": "SA",
    },
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Home Loans and Financial Services",
      "description":
        "Go Loans Motgage Brokers provide assistance for a number of different home loans and financial services.",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Refinancing",
            "description":
              "Here's what you need to know about Refinancing: You have the option to 'Refinance' or 'Increase'. Both of these enable you to access equity that you have sitting in your property. 'Increasing' is when you stay with your existing lender and increase your loan amount.",
          },
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Buying a Property",
            "description":
              "Interested in buying a property? Go Loans Mortgage Brokers have you covered, whether you're wanting to purchase a house or build one from the ground up.",
          },
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Car Loans",
            "description":
              "So you’ve selected the car, now you just want to pick it up!  We get it. With in-house car loan specialists, you’ll be driving your new addition before you know it.",
          },
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Personal Loans",
            "description":
              "A few things for you to consider when thinking about Personal Lending: Personal loans include loans for holidays, cars, boats, caravans and the like.",
          },
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "IBO Loans",
            "description":
              "Go Loans are your exclusive home loan provider. Your partner store Go Loans have been assisting IBO's with all their finance needs since 2012 and are an IBO’s first choice for all their borrowing requirements.",
          },
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Small Business Loans",
            "description":
              "Do you have your own business and are currently looking for a small business loan? Fill in the below application form and get a quick response for short term business loans. ",
          },
        },
      ],
      "url": "https://www.goloans.com.au/services",
    },
  };
  const blogPostingStructuredData = {
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    "headline": pageData.pageTitle,
    "description": description,
    "image": heroImageUrl,
    "datePublished":
      datePublishedForBlog && datePublishedForBlog !== ""
        ? datePublishedForBlog
        : "",
    "dateModified": pageData.dateUpdated,
    "author": {
      "@type": "Organization",
      "name": "Go Loans",
      "url": "https://www.goloans.com.au",
    },
  };

  return (
    <>
      <MetaTag data={getMetaTag(pageData)} />
      {pageData.pageUrl.startsWith("/") && (
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(homeStructuredData)}
          </script>
        </Helmet>
      )}
      {pageData.pageUrl === "/services" && (
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(servicesStructuredData)}
          </script>
        </Helmet>
      )}
      {pageData.pageTypeCode && pageData.pageTypeCode === "BlogPost" && (
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(blogPostingStructuredData)}
          </script>
        </Helmet>
      )}
      <div className={`cmsPage cmsPage--${pageData.pageTypeCode}`}>
        {sectionItems}
      </div>
    </>
  );
}

function Section(props) {
  log("5. section rendering");
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0) return <></>;

  const subItemsElts = subItems.map((subItem, index) => (
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  ));
  return <>{subItemsElts}</>;
}

function getMetaTag(pageData) {
  const country = utils.site.isNZ ? "New Zealand" : "Australia";
  const siteName = env.siteName + " " + country;

  return {
    title: pageData.pageTitle + " | " + siteName,
    description: cmsUtils.payload(pageData, "Description"),
    keywords: cmsUtils.payload(pageData, "Keyword"),
    heroImageUrl: utils.site.resourcePath(
      cmsUtils.payload(pageData, "ImageUrl")
    ),
    language: "English",
    country: country,
    siteName: siteName,
    url: utils.site.resourcePath(pageData.pageUrl),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}
