import { fb, validators } from '../../../lib/form';

export function getContactModel(data) {

  const loanTypeList = [
    { name: 'Loan Type*', value: '' },
    { name: 'Purchase', value: 'Purchase' },
    { name: 'Refinance', value: 'Refinance' },
    { name: 'Car Loan', value: 'Car Loan' },
    { name: 'Personal Loan', value: 'Personal Loan' },
    { name: 'Commercial Loan', value: 'Commercial Loan' },
    { name: 'Business Loan', value: 'Business Loan' },
    { name: 'Other', value: 'Other' }
  ];

  const model = fb.group({
    name: [data.name || '', [validators.Required()], { label: 'Name', type: 'text' }],
    phone: [data.phone || '', [validators.Required()], { label: 'Phone', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
    loanType: [data.loanType || '', [validators.Required()], { label: null, type: 'select', options: loanTypeList }],
    enquiry: [data.enquiry || '', [validators.Required()], { label: 'Your Message', type: 'textarea', style: { rows: 4 } }]
  });

  return model;
  
}