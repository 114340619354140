import cmsUtils from '../../cms/utils/cmsUtils';
import utils from '../../utils';
//import { SiteLink } from '../../components';
import { useEffect, Fragment } from 'react';

/** @jsx jsx */
import { jsx } from '@emotion/core'
//import style from './style';

export function Calculator(props){

    const item = props.item;
    const type = cmsUtils.payload(item, 'Type');
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'calculator__item', item.cssClass || item.anchorName || '');

    useEffect(() => {
        const existingScript = document.getElementById('calculatorItem');
        if(existingScript){
            document.body.removeChild(existingScript);
        }
        const script = document.createElement('script');
        script.src = 'https://mercury.connective.com.au/calculators/js/CodeEmbeder.js';
        script.id = 'calculatorItem';
        document.body.appendChild(script);
    }, []);

    return(
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} style={{maxWidth:'960px',margin:'2rem auto'}}>
            {
                cmsOption.isCmsEdit ?
                <pre style={{fontSize: '12px', margin:'5px', padding: '10px', background:'#eee', border: '1px solid #ccc'}}>
                    <b> { 'Calculator Widget'} </b>
                </pre> :
                <Fragment>
                    {type === 'borrowing-power' && <div id="connective-borrowing-capacity-calculator"></div>}
                    {type === 'stamp-duty' && <div id="connective-property-fees-calculator"></div>}
                    {type === 'extra-repayments' && <div id="connective-repayments-calculator"></div>}
                    {/* <div onClick={() => {
                        
                        //const a = document.querySelector('#borrowingCapacityIframe');
                        //console.log('sfsdf', a.querySelector('#connective-htmlcw-bc-monthly-repayments'));
                        //const aaaa = document.getElementById('#borrowingCapacityIframe').contentWindow.document.getElementById('connective-htmlcw-bc-monthly-repayments');
                        const aaaa = document.getElementById('borrowingCapacityIframe').contentWindow.document.getElementById('connective-htmlcw-bc-monthly-repayments');
                        console.log('222', aaaa);
                    }}> collet test </div> */}
                </Fragment>
            } 
        </div>
    )
}